import axios from 'axios'


export default {

    async saveAll(params, callback) {
        return await axios.post("/app/dashboard/system/privacies/saveall", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },



}

