<template>
  <div>
    <navbar></navbar>
    <v-container>

      <v-row class="alert mt-4">
        <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="error_msg != null">
          <v-alert dense type="warning">{{ error_msg }}</v-alert>
        </v-col>
        <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="success_msg != null">
          <v-alert dense type="success">{{ success_msg }}</v-alert>
        </v-col>
      </v-row>

      <v-row class="privacies pa-2 mb-5" style="display:flex;justify-content:center;align-items: center;">
        <v-card outlined class="radius-10 col-12 mb-5" flat>
          <v-card-text>
            <v-container fluid>







              <v-row class="mb-1 mt-2">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ma-auto text-center">
                  <h1> {{ langkeyword('SALE') }} </h1>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('MAIN') + '-' + langkeyword('CURRENCY') }} </h3>
                  <v-radio-group hide-details class="text-main" @change="saveAll()" v-model="maincurrencysale">
                    <v-radio class="text-main" :label="langkeyword('DINAR')" value="iqd"></v-radio>
                    <v-radio :label="langkeyword('DOLLAR')" value="usd"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('MAIN') + '-' + langkeyword('INVOICETYPE') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="maininvoicetypesale">
                    <v-radio :label="langkeyword('CASH')" value="cash"></v-radio>
                    <v-radio :label="langkeyword('DEBT')" value="debt"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DOLLAR') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="hidedollarsale">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DINAR') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="hidedinarsale">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('UNITS') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="hideunitssale">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DISCOUNT') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="hidediscountsale">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('INVOICETYPE') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="hideinvoicetypesale">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DRIVERWAGE') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="hidedriverwagesale">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('PROFIT') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="hideprofitsale">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <hr style="width:75%;margin:auto;">













              <v-row class="mb-1 mt-2">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ma-auto text-center">
                  <h1> {{ langkeyword('BUY') }} </h1>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('MAIN') + '-' + langkeyword('CURRENCY') }} </h3>
                  <v-radio-group hide-details class="text-main" @change="saveAll()" v-model="maincurrencybuy">
                    <v-radio class="text-main" :label="langkeyword('DINAR')" value="iqd"></v-radio>
                    <v-radio :label="langkeyword('DOLLAR')" value="usd"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('MAIN') + '-' + langkeyword('INVOICETYPE') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="maininvoicetypebuy">
                    <v-radio :label="langkeyword('CASH')" value="cash"></v-radio>
                    <v-radio :label="langkeyword('DEBT')" value="debt"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DOLLAR') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="hidedollarbuy">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DINAR') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="hidedinarbuy">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('UNITS') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="hideunitsbuy">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DISCOUNT') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="hidediscountbuy">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('INVOICETYPE') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="hideinvoicetypebuy">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DRIVERWAGE') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="hidedriverwagebuy">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <hr style="width:75%;margin:auto;">











              <v-row class="mb-1 mt-2">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ma-auto text-center">
                  <h1> {{ langkeyword('EXPENSE') }} </h1>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('MAIN') + '-' + langkeyword('CURRENCY') }} </h3>
                  <v-radio-group hide-details class="text-main" @change="saveAll()" v-model="maincurrencyexpense">
                    <v-radio :label="langkeyword('DINAR')" value="iqd"></v-radio>
                    <v-radio :label="langkeyword('DOLLAR')" value="usd"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('MAIN') + '-' + langkeyword('INVOICETYPE') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="maininvoicetypeexpense">
                    <v-radio :label="langkeyword('CASH')" value="cash"></v-radio>
                    <v-radio :label="langkeyword('DEBT')" value="debt"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DOLLAR') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="hidedollarexpense">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DINAR') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="hidedinarexpense">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('INVOICETYPE') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="hideinvoicetypeexpense">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <hr style="width:75%;margin:auto;">







              <v-row class="mb-1 mt-2">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ma-auto text-center">
                  <h1> {{ langkeyword('PRODUCT') }} </h1>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DOLLAR') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="hidedollarproduct">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DINAR') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="hidedinarproduct">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('UNITS') }} </h3>
                  <v-radio-group hide-details class="text-mode" @change="saveAll()" v-model="hideunitsproduct">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <hr style="width:75%;margin:auto;">









            </v-container>
          </v-card-text>
        </v-card>
      </v-row>
      <br><br><br>

    </v-container>
    <dashboardFooter />
  </div>
</template>

<script>
import privaciesRequests from "../../../requests/dashboard/system/Privacies";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
export default {
  props: ["items"],
  data() {
    return {
      maincurrencysale: this.$store.state.setting.maincurrencysale,
      maininvoicetypesale: this.$store.state.setting.maininvoicetypesale,
      hidediscountsale: this.$store.state.setting.hidediscountsale,
      hideunitssale: this.$store.state.setting.hideunitssale,
      hidedollarsale: this.$store.state.setting.hidedollarsale,
      hidedinarsale: this.$store.state.setting.hidedinarsale,
      hideprofitsale: this.$store.state.setting.hideprofitsale,
      hideinvoicetypesale: this.$store.state.setting.hideinvoicetypesale,
      hidesaveconfirmationsale: this.$store.state.setting.hidesaveconfirmationsale,
      hidedriverwagesale: this.$store.state.setting.hidedriverwagesale,

      maincurrencybuy: this.$store.state.setting.maincurrencybuy,
      maininvoicetypebuy: this.$store.state.setting.maininvoicetypebuy,
      hidediscountbuy: this.$store.state.setting.hidediscountbuy,
      hideunitsbuy: this.$store.state.setting.hideunitsbuy,
      hidedollarbuy: this.$store.state.setting.hidedollarbuy,
      hidedinarbuy: this.$store.state.setting.hidedinarbuy,
      hideinvoicetypebuy: this.$store.state.setting.hideinvoicetypebuy,
      hidedriverwagebuy: this.$store.state.setting.hidedriverwagebuy,

      maincurrencyexpense: this.$store.state.setting.maincurrencyexpense,
      maininvoicetypeexpense: this.$store.state.setting.maininvoicetypeexpense,
      hidedollarexpense: this.$store.state.setting.hidedollarexpense,
      hidedinarexpense: this.$store.state.setting.hidedinarexpense,
      hideinvoicetypeexpense: this.$store.state.setting.hideinvoicetypeexpense,

      hidedollarproduct: this.$store.state.setting.hidedollarproduct,
      hidedinarproduct: this.$store.state.setting.hidedinarproduct,
      hideunitsproduct: this.$store.state.setting.hideunitsproduct,


      error_msg: null,
      success_msg: null
    };
  },
  components: {
    navbar,
    dashboardFooter,
  },
  methods: {
    cleanMessages() {
      setTimeout(() => {
        this.success_msg = null;
        this.error_msg = null;
      }, 3000);
    },

    saveAll() {
      privaciesRequests.saveAll(
        {
          saveAllPrivacies: true,
          maincurrencysale: this.maincurrencysale,
          maincurrencybuy: this.maincurrencybuy,
          maincurrencyexpense: this.maincurrencyexpense,
          maininvoicetypesale: this.maininvoicetypesale,
          maininvoicetypebuy: this.maininvoicetypebuy,
          maininvoicetypeexpense: this.maininvoicetypeexpense,
          hidediscountsale: this.hidediscountsale,
          hidediscountbuy: this.hidediscountbuy,
          hideunitsproduct: this.hideunitsproduct,
          hideunitssale: this.hideunitssale,
          hideunitsbuy: this.hideunitsbuy,
          hidedollarproduct: this.hidedollarproduct,
          hidedollarsale: this.hidedollarsale,
          hidedollarbuy: this.hidedollarbuy,
          hidedollarexpense: this.hidedollarexpense,
          hidedinarproduct: this.hidedinarproduct,
          hidedinarsale: this.hidedinarsale,
          hidedinarbuy: this.hidedinarbuy,
          hidedinarexpense: this.hidedinarexpense,
          hideprofitsale: this.hideprofitsale,
          hideinvoicetypesale: this.hideinvoicetypesale,
          hideinvoicetypebuy: this.hideinvoicetypebuy,
          hideinvoicetypeexpense: this.hideinvoicetypeexpense,
          hidedriverwagesale: this.hidedriverwagesale,
          hidedriverwagebuy: this.hidedriverwagebuy
        },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            //  this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.setting = res.data.data;
          }
          this.cleanMessages();
        }
      );
    },
  },
  mounted() {
  },
};
</script>

<style>
.privacies {
  margin-top: 50px !important;
}

.theme--dark .dashboard .v-list {
  background: var(--another) !important;
}

.vue-swatches__trigger {
  border: 1px solid white !important;
}
</style>
